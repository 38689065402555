import { News } from './shared/types'

import { NgIf } from '@angular/common'
import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { RouterLink } from '@angular/router'

import { NavigationUrl } from 'src/app/core/constants/navigation'

@Component({
  selector: 'app-main-news-card',
  standalone: true,
  imports: [NgIf, RouterLink],
  templateUrl: './main-news-card.component.html',
  styleUrl: './main-news-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainNewsCardComponent {
  @Input({ required: true }) card!: News

  @Input() isMainNews!: boolean

  navigation = NavigationUrl
}
