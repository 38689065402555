import { certificateFaq, commonFaq, signFaq } from './main-faq.constants'
import { FaqCol } from './shared/types'

import { NgFor } from '@angular/common'
import { ChangeDetectionStrategy, Component } from '@angular/core'
import { RouterLink } from '@angular/router'

import { NavigationUrl } from 'src/app/core/constants/navigation'

@Component({
  selector: 'app-main-faq',
  standalone: true,
  imports: [NgFor, RouterLink],
  templateUrl: './main-faq.component.html',
  styleUrl: './main-faq.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainFaqComponent {
  cols: FaqCol[] = [
    {
      title: 'Загальні питання',
      items: commonFaq,
    },
    {
      title: 'Електронний підпис',
      items: signFaq,
    },
    {
      title: 'Кваліфікований сертифікат',
      items: certificateFaq,
    },
  ]

  navigation = NavigationUrl
}
