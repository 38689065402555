import { TrustServicesCard } from 'src/app/features/main/components/trust-services-card/shared/types'
import { TrustServicesCardComponent } from 'src/app/features/main/components/trust-services-card/trust-services-card.component'

import { NgFor } from '@angular/common'
import { ChangeDetectionStrategy, Component } from '@angular/core'

import { NavigationUrl } from 'src/app/core/constants/navigation'

@Component({
  selector: 'app-trust-services',
  standalone: true,
  imports: [NgFor, TrustServicesCardComponent],
  templateUrl: './trust-services.component.html',
  styleUrl: './trust-services.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TrustServicesComponent {
  mockCards: TrustServicesCard[] = [
    {
      title: 'Придбати сертифікат',
      desc: 'Тут ви можете замовити кваліфікований сертифікат.',
      link: NavigationUrl.Order,
    },
    {
      title: 'Оновити сертифікат',
      desc: 'Тут ви можете оновити власний сертифікат, якщо термін його дії ще не завершився, а ваші персональні дані не змінювалися.',
      link: NavigationUrl.Update,
    },
    {
      title: 'Пункти обслуговування',
      desc: 'Тут ви можете ознайомитись з переліком пунктів надання послуг, в яких можна придбати сертифікат кваліфікованого електронного підпису.',
      link: NavigationUrl.Contacts,
    },
    {
      title: 'Підписати документ',
      desc: 'Тут ви можете підписати документ за допомогою електронного підпису.',
      link: NavigationUrl.Sign,
    },
    {
      title: 'Перевірити підпис',
      desc: 'Тут ви можете перевірити документ, підписаний КЕП.',
      link: NavigationUrl.CheckSign,
    },
    {
      title: 'Знайти сертифікат',
      desc: 'Тут ви можете ознайомитись з переліком пунктів надання послуг, в яких можна придбати сертифікат кваліфікованого електронного підпису.',
      link: NavigationUrl.SearchCertificates,
    },
  ]
}
