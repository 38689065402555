<div class="faq bg-background-main py-40 md:py-80">
  <div class="px-16">
    <div class="wrapper">
      <h2>Питання та відповіді</h2>
      <div class="mt-40 md:mt-80 flex items-start w-full justify-between gap-30 flex-col md:flex-row">
        <div *ngFor="let col of cols" class="faq__col flex flex-col gap-30">
          <span class="faq__title opacity-50 relative">{{ col.title }}</span>

          <div class="faq__items accordion flex flex-col gap-20">
            <a *ngFor="let item of col.items" [routerLink]="navigation.Faq + '/' + item.id" class="text-large">
              {{ item.question }}
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
